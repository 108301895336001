.root {
  min-height: 100vh;
}
.btn {
  margin-top: 5rem;
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
}
