.image {
  margin-bottom: .5rem;
}

.image > img {
  display: block;
  width: 100%;
}

.primary {
  margin-bottom: .5rem;
}

.meta {
  font-size: .8rem;
  color: var(--adm-color-weak);
}