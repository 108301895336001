.container {
  position: absolute;
  height: 100%;
  width: 100%;
}
.root {
  margin: 0 auto 0 auto;
  max-width: 36rem;
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: var(--adm-color-white);
}
.login {
  padding: 3rem;
}
.login > .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.login > .welcome {
  font-size: 0.875rem;
  margin-bottom: 3rem;
}

.header {
  margin-bottom: 1rem;
}
.header > img {
  display: block;
  width: 100%;
}
