.root {
  padding: .5rem;
  background-color: #FFFFFF;
  border-radius: .5rem;
}

.header {
  width: 100%;
}

.header > img {
  width: 100%;
}
.body {
  padding: .5rem 0 1rem 0;
  text-align: center;
}

.body > .primary {
  font-size: 1rem;
  font-weight: 500;
}

.body > .secondary {
  color: var(--adm-color-weak);
}