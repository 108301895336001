.header {
  margin-top: .5rem;
  font-size: .875rem;
}

.title {
  font-size: 1.2rem;
  margin-bottom: 4px;
  /* 标题和时间之间的间距 */
  font-weight: bold;
}

.noticeItem {
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
}

.date {
  color: #888;
  /* 日期的颜色 */
  font-size: 0.875rem;
}

.detail_info {
  padding: 1rem;
  background: #FFF;
}

.detail_time {
  margin-top: -.5rem;
  color: rgba(0, 0, 0, .5);
}

.detail_content {
  font-size: 1rem;
}

.detail_content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}