.root {
  padding: 1rem;
}
.icon {
  position: absolute;
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  right: 1rem;
  top: 1rem;
  background: #9d9f9d;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.title {
  font-size: 1.2rem;
}
.content {
  padding: 1rem;
  font-size: 1rem;
}
/* .btn {
} */
