.feature {
  display: block;
  width: 100%;
}

.title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.meta {
  font-size: .875rem;
  color: rgba(0, 0, 0, .5);
  text-align: right;
}

.content {
  line-height: 2;
}

.content img {
  display: block;
  width: 100%;
}

.footer {
  padding: 1rem 0 3rem 0;
  text-align: center;
  font-size: .875rem;
  color: var(--adm-color-light);
}