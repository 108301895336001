
.meta {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
}

.highlights {
  position: relative;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 2;
  font-size: 0.875rem;
}

.highlights :global(.anticon) {
  margin-right: 0.5rem;
}

.highlights > .stamp {
  position: absolute;
  top: -5rem;
  right: 0;
  width: 10rem;
}

.feature {
  display: block;
  width: 100%;
}

.footer {
  margin: 1rem 0 3rem 0;
  text-align: center;
  font-size: 0.875rem;
  color: var(--adm-color-light);
}

.actions {
  position: sticky;
  bottom: 0;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
