.tabs {
  --content-padding: 0;
}

.header {
  margin-top: .5rem;
  font-size: .875rem;
}

.image {
  display: block;
  margin: 1rem 0;
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  border: 1px var(--adm-color-border) solid;
  border-radius: 8px;
}

.icon {
  display: block;
  margin: 1rem 0;
  width: 3rem;
  height: 3rem;
  object-fit: contain;
}

.list {
  margin: .5rem 0;
  padding-left: 1.5rem;
}

.list > li {
  margin-bottom: .5rem;
}