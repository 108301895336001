.root {
  margin-bottom: 1rem;
  background-color: #FFFFFF;
}

.root > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 2.5rem;
  font-size: 1rem;
}

.root > .header a {
  text-decoration: none;
}

.root > .header > .title {
  font-weight: 500;
}

.root > .header > .title :global(.anticon) {
  margin-right: .25rem;
  color: var(--adm-color-text-secondary);
}

.root > .header > .link :global(.anticon) {
  margin-left: .25rem;
}