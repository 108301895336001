.root {
  min-height: 100vh;
}
.feature {
  display: block;
  width: 100%;
}

.actions {
  position: sticky;
  bottom: 0;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
