.container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.root {
  margin: 0 auto 0 auto;
  max-width: 36rem;
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: var(--adm-color-white);
}

.header {
  /* padding: 1rem; */
  margin-bottom: 1rem;
}

.header > img {
  display: block;
  width: 100%;
}

/* .header > h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: 500;
}

.header > p {
  margin: 1rem 0 2rem 0;
  font-size: 1rem;
  color: var(--adm-color-text-secondary);
}

.header > p a {
  text-decoration: none;
} */

.password {
  display: flex;
  align-items: center;
}

.password > .toggle {
  margin-left: 0.5rem;
}
.link_box {
  display: flex;
  justify-content: space-between;
}
.link {
  display: inline-block;
  margin: 1rem 0;
  font-size: 1rem;
  text-decoration: none;
}
