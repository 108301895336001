.container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.root {
  margin: 0 auto 0 auto;
  max-width: 36rem;
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding-top: 10vh;
  background-color: var(--adm-color-white);
}
