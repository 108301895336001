.popup {
  height: 100vh;
  height: -webkit-fill-available;
  overflow-y: scroll;
}

.popupBtn {
  font-size: .875rem;
  cursor: pointer;
}

.popupBtn.primary {
  color: var(--adm-color-primary);
}

.popup > :global(.adm-nav-bar) {
  position: sticky;
  top: 0;
  background-color: var(--adm-color-white);
  z-index: 1001;
}

.search {
  padding: .5rem;
}