.root {
  padding: 0.5rem;
  background-color: var(--adm-color-box);
  border-radius: 0.5rem;
}

.header {
  width: 100%;
}

.header > img {
  width: 100%;
  height: 5rem;
  object-fit: contain;
  border-radius: 0.5rem;
  background-color: #ffffff;
}
.body {
  padding: 0.5rem 0 1rem 0;
  text-align: center;
}

.body > .primary {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.body > .secondary {
  color: var(--adm-color-weak);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
