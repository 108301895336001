.root {
  width: 100%;
  height: 18rem;
}

.background {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, .6); */
  text-align: center;
}

.content > .img_box {
  margin-top: 2rem;
  height: 5rem;
}
.content > .img_box > .logo {
  height: 100%;
  width: auto;
}

.content > .primary {
  margin-top: 2rem;
  font-size: 1.75rem;
  font-weight: 500;
  color: #ffffff;
  padding: 0 1rem;
}

.content > .secondary {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #ffffff;
  padding: 0 1rem;
}
