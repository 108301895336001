.root > :global(.adm-list) {
  margin-top: 0.5rem;
}

.card {
  display: flex;
  align-items: center;
  margin: 1rem;
  padding: 0 2rem;
  padding-right: 1rem;
  color: #ffffff;
  border-radius: 0.5rem;
  background: #333333 url("./bg-card.png") no-repeat;
  background-size: cover;
  height: 11rem;
}

.card > .body {
  flex: 1;
}

.card .title {
  font-size: 1rem;
  font-weight: 500;
}

.card .address {
  margin-top: 3rem;
  color: rgba(255, 255, 255, 0.75);
}

.card.add {
  padding: 0 2rem;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
}

.add > .button > .icon {
  font-size: 3rem;
  text-align: center;
}

.add > .button > .label {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.statistics {
  display: flex;
}

.statistics .divider {
  width: 1px;
  height: 5rem;
  background-color: var(--adm-color-border);
}

.statistics > .item {
  flex: 1;
  text-align: center;
  padding: 0.5rem 0 0.5rem 0;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  user-select: none;
}

.statistics > .item:hover {
  cursor: pointer;
}

.statistics > .item:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.statistics > .item .label {
  font-size: 0.875rem;
  color: var(--adm-color-text-secondary);
}

.statistics > .item .value {
  font-size: 1.5rem;
}

.statistics > .item .warning {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: var(--adm-color-warning);
}

.orderStatistics {
  display: flex;
  align-items: center;
}

.orderStatistics > .item {
  flex: 1;
  padding: 0.5rem 0 0.5rem 0;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  user-select: none;
}

.orderStatistics > .item:hover {
  cursor: pointer;
}

.orderStatistics > .item:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.orderStatistics > .item .icon {
  display: block;
  width: 2rem;
  height: 2rem;
}

.orderStatistics > .item .label {
  font-size: 0.875rem;
  color: var(--adm-color-text-secondary);
}

.danger {
  color: var(--adm-color-danger);
}

.footer {
  margin: 1rem 0 3rem 0;
  text-align: center;
  font-size: 0.875rem;
  color: var(--adm-color-light);
}
