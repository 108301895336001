.root {
  display: flex;
  margin-bottom: 2rem;
  user-select: none;
}

.root > :global(.adm-avatar) {
  margin-top: .5rem;
  flex-shrink: 0;
}

.root > .wrapper {
  margin-left: 1rem;
  margin-right: 0;
}

.root.reverse {
  flex-direction: row-reverse;
}

.root.reverse > .wrapper {
  margin-left: 0;
  margin-right: 1rem;
}

.root .body > .arrow {
  position: absolute;
  top: .75rem;
  left: -.5rem;
  width: 0;
  height: 0;
  border-top: .5rem solid transparent;
  border-right: .5rem solid rgba(0, 0, 0, .08);
  border-bottom: .5rem solid transparent;
  border-left: none;
  z-index: 1;
}

.root.reverse .body > .arrow {
  left: auto;
  right: -.5rem;
  border-right: none;
  border-left: .5rem solid rgba(0, 0, 0, .08);
}

.header, .footer {
  font-size: .8125rem;
  color: rgba(0, 0, 0, .5);
}

.header {
  margin-bottom: .25rem;
}

.root.reverse .header, .root.reverse .footer {
  text-align: right;
}

.body {
  flex: 1;
  position: relative;
  padding: .75rem;
  background-color: rgba(0, 0, 0, .08);
  border-radius: .5rem;
  user-select: text;
}

.footer {
  margin-top: .25rem;
}

.attachments > a {
  display: block;
  max-width: 65vw;
  margin-top: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}

.attachments > a > :global(.anticon) {
  margin-right: .25rem;
}