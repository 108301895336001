.root {
  margin: 0 auto 0 auto;
  width: 100vw;
  max-width: 36rem;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: var(--adm-color-box);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.75);
}
.mask {
  margin: 0 auto;
  max-width: 36rem;
  min-height: 100vh;
  /* min-height: -webkit-fill-available; */
  padding: 1rem;
  padding-top: 15vh;
  background-color: var(--asdm-color-white);
}
@media (min-width: 90rem) {
  /* 桌面屏幕宽度下的样式定义 */
}
