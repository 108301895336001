.root {
  position: sticky;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
  background-color: #FFFFFF;
}

.body {
  display: flex;
  padding: .5rem;
}

.attachments {
  padding-bottom: .5rem;
}

.attachments > .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem 0 1rem;
  font-size: 1rem;
}

.attachments > .item a {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.attachments > .item a > :global(.anticon) {
  margin-right: .25rem;
}