.meta {
  display: flex;
  align-items: center;
}

.thumb {
  width: 4rem;
  margin-right: 0.5rem;
}

.qrcode {
  display: block;
  width: 100%;
}

.footer {
  padding: 1rem 0 3rem 0;
  text-align: center;
  font-size: 0.875rem;
  color: var(--adm-color-light);
}
.mask {
  margin: 0 auto;
  max-width: 36rem;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 1rem;
  padding-top: 15vh;

  background-color: var(--asdm-color-white);
}

