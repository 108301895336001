.root > :global(.adm-list) {
  margin-top: .5rem;
}

.tabs {
  --content-padding: 0;
}

.icon {
  display: block;
  margin: .75rem 0;
  width: 4rem;
  height: 4rem;
  border-radius: .5rem;
  object-fit: cover;
}