.root {
  margin: 0 auto 0 auto;
  max-width: 36rem;
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: var(--adm-color-white);
}

.header {
  margin-bottom: 1rem;
}

.header > img {
  display: block;
  width: 100%;
}

.password {
  display: flex;
  align-items: center;
}

.password > .toggle {
  margin-left: 0.5rem;
}

.link {
  display: inline-block;
  margin: 1rem 0;
  font-size: 1rem;
  text-decoration: none;
}

.popup {
  height: 100vh;
  height: -webkit-fill-available;
  overflow-y: scroll;
}

.popupBtn {
  font-size: 0.875rem;
  cursor: pointer;
}

.popupBtn.primary {
  color: var(--adm-color-primary);
}

.popup > :global(.adm-nav-bar) {
  position: sticky;
  top: 0;
  background-color: var(--adm-color-white);
  z-index: 1001;
}

.search {
  padding: 0.5rem;
}

.footer {
  padding-bottom: 2rem;
}
